.section-a {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  background: #fafafa;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.site-container {
  background-color: #fafafa;
  scroll-behavior: smooth;
}

.chapter {
  margin-top: 75px;
}

.chapter h3 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  color: #234d6d;
  margin: 0;
  padding: 50px 0;
}

.source-show {
  display:block;
}
.source-hide {
  display:none;
}

.submitForm {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  display: none;
}

.submitForm.success {
  color: green;
  display: block;
}

.submitForm.fail {
  color: red;
  display: block;
}



@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 480px) {

  .chapter {
    margin-top: 25px;
  }
  
  .chapter h3 {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    color: #234d6d;
    margin: 0;
    padding: 30px 0;
  }
}