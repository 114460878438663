.carousel {
    width: 100%;
    height: 200px;
}

.carousel-inner {
    width: 80%;
    height: 100%;
    box-shadow: 1px 1px 10px 1px #aaa;
    margin: 0 auto;
    text-align: center;
    padding: 0 30px;
    display: flex;
    align-items: center;
}

.carousel-inner h3{
    height: 50%;
    /* margin-bottom: 1em;
    margin-top: 1em; */
    font-size: 1.2em;
    font-style: italic;
}

.carousel-caption {
    position: absolute;
    color: #234d6d;
    /* left: auto;
    right: auto;
    top: 0; */
    bottom: auto;
    height: 10%;
    padding-top: 0;
    text-align: right;
    padding-right: 1em;
}

.carousel-indicators li {
    background-color: #234d6d;
}

.carousel-control-next, .carousel-control-prev {
    /* background-color: #234d6d; */
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    background-color: #234d6d;
    border-radius: 50%;
    padding: 1em;
}

@media only screen and (max-width: 1024px) {
    .carousel-inner h3{
        font-size: 1.1em;
    }  
}

@media only screen and (max-width: 768px) {
    .carousel-inner h3{
        font-size: 0.8em;
    } 
}

@media only screen and (max-width: 480px) {
    .carousel-inner h3{
        font-size: 0.7em;
    } 
  
}