.about {
    width: 80%;
  }
  
  .about p {
    font-size: 1.2em;
  }
  
  .about .lesson {
    /* margin-top: 30px; */
  }
  
  .about h3, h4 {
    font-weight: bold;
    margin-top: 30px;
  }
  
  .about-lesson {
  
    display: flex;
    flex-direction: row;
  }
  
  .lesson-content {
    display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
  }
  
  .lesson-content img {
    margin-top: 20px;
    width: 75%;
    height: auto;
    margin: 0 auto;
  }
  
  .about-lesson-img {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 10%;
  }
  
  .about-lesson-img img {
    width: 60%;
    height: auto;
    margin: 0 auto;
  }

.offering-content {
    display: flex;
    flex-direction: row;
  
  }
  
  .offering-content li {
    font-size: 1.1em;
    padding: 10px;
  }
  
  #trainer {
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
  }
  
  
  
  .advantage-list {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    font-size: 1.1em;
    text-align: center;
  }
  .advantage-list div {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2em;
  }
  .advantage-list img {
    width: 3em;
    height: 3em;
    margin-bottom: 1.5em;
  }
  
  #advantage {
    text-align: center;
  }


  @media only screen and (max-width: 1024px) {
    .advantage-list div {
        width: 50%;
    }

    .offering-content {
        flex-direction: column;
      
      }
    .lesson-content img {
        width: 80%;
    }
    .about-lesson-img img {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .about {
        width: 90%;
        font-size: 0.9em;
      }
    #trainer {
        text-align: left;
    }
    .advantage-list {
        width: 100%;
        justify-content: center;
    }
    .advantage-list div {
        width: 60%;
        padding: 0.5em;
    }
    .advantage-list img {
        margin-bottom: 10px;
    }
    .about-lesson {
        width: 80%;
        flex-direction: column;
        margin: 0 auto;
    }
    .about-lesson-img {
        margin: 0;
    }
    .lesson-content img {
      width: 75%;
    }
    .about-lesson-img img {
        width: 75%;
        margin-top: 30px;
    }
    .offering-content {
        flex-direction: column;
      
      }
}
@media only screen and (max-width: 480px) {
    .about {
        width: 90%;
        font-size: 0.9em;
      }
    #trainer {
        text-align: left;
    }
    .advantage-list {
        width: 100%;
        justify-content: center;
    }
    .advantage-list div {
        width: 90%;
        padding: 0.5em;
    }
    .about-lesson {
        flex-direction: column;
    }
    .about-lesson-img {
        margin: 0;
    }
    .about-lesson-img img {
        width: 75%;
        margin-top: 30px;
    }
    .offering-content {
        flex-direction: column;
      
      }
}
