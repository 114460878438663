.faq {
    width: 100%;
  }
  
  .faq button {
    text-align: left;
  }

  .faq .collapse {
    width: 100%;
  }