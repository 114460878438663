.nav {
    width: 100%;
    background-color: #fafafa;
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    box-shadow: 0 0 5px #ccc;
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .nav.smaller {
    height: 75px;
    transition: all 0.3s;
  }
  .nav.smaller .collapse {
    background-color: #fafafa;
    padding: 10px;
  }
  .nav.smaller .collapse.show {

    border-bottom: 1px solid #ccc;
  }

  .collapse:active {
    /* display: none; */
  }
  
  .nav.smaller .navbar {
    padding: .5em;
    padding-top: 0;
    transition: all 0.3s;
  }
  .nav.smaller .navbar-nav a {
      font-size: 1.2em;
  }
  .nav.smaller .menu {
      margin: 10px;
  }
  .nav.smaller #logo-img {
    margin: 10px;
    height: 50px;
    transition: all 0.3s;
  }
  
  .navbar-nav {
    /* margin: 15px 30px; */
    /* display: flex;
    align-items: center; */
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .navbar-nav a {
    text-decoration: none;
    color: #234d6d;
    font-size: 1.3em;
    /* margin: 10px; */
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
  }
  .navbar-nav a:hover {
    font-weight: bold;
  }
  
  .navbar-nav a#apply-btn {
    background: #fafafa;
    padding: 5px;
    color: #234d6d;
    border: 3px solid #234d6d;
    border-radius: 7px;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    width: fit-content;
  }
  
  .navbar-nav a#apply-btn:hover {
    background: #234d6d;
    color: #fafafa;
  }
  
  .logo-img {
    width: 15%;
    margin: 15px 30px;
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .logo img {
    width: 100%;
    height: auto;
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
  }

  

.btn-outline-dark:hover {
    background: #234d6d;
    color: #fafafa;
  }
  
  .btn-outline-dark a {
    color: #234d6d;
    text-decoration: none;
  }
  
  .btn-outline-dark a:hover {
    color: #fafafa;
  }
  
.bg-light {
    background: transparent !important;
}

#logo-img {
    height: 80px;
}

.navbar {
    padding: 1em;
    width: 100%;
    height: 100%;
}
.navbar-nav {
    width: 100%;
    justify-content: flex-end;
}

.navbar-light .navbar-nav .nav-link {
    color: #234d6d;
}

  @media only screen and (max-width: 1024px) {
    .nav {
        align-items: baseline;
    }
    .logo {
        width: 20%;
        margin: 15px;
    }
    .menu {
        margin: 10px;
    }
    .menu a {
        font-size: 1em;
    }
    .nav.smaller {
        height: 75px;
        transition: all 0.3s;
      }
      .nav.smaller .menu a {
          font-size: 0.9em;
      }
      .nav.smaller .menu {
          margin: 10px;
      }
      .nav.smaller .logo {
        margin: 10px;
        width: 150px;
        transition: all 0.3s;
      }
}


@media only screen and (max-width: 768px) {
    .logo {
        width: 30%;
        margin: 15px 5px;
        /* margin-left: 18%; */
    }

  .nav.smaller {
    height: 50px;
  }
  .nav.smaller .menu a {
      font-size: 1em;
  }
  .nav.smaller .menu {
      /* margin: 10px; */
  }
  .nav.smaller .logo {
    /* margin: 10px; */
    width: 100px;
  }
  #logo-img {
    height: 50px;
}
    .menu {
        display: none;
    }

    .nav.smaller #logo-img {
      margin: 10px;
      height: 27px;
      transition: all 0.3s;
    }
}

@media only screen and (max-width: 480px) {
    .logo {
        width: 30%;
        margin: 15px 5px;
        /* margin-left: 18%; */
    }
    #logo-img {
      height: 30px;
  }

  .nav.smaller {
    height: 50px;
  }
  .nav.smaller .menu a {
      font-size: 1em;
  }
  .nav.smaller .menu {
      /* margin: 10px; */
  }
  .nav.smaller .logo {
    /* margin: 10px; */
    width: 100px;
  }

    .menu {
        display: none;
    }
}

@media only screen and (max-height: 400px) {
  #logo-img {
    height: 20px;
  }

  .subtitle {
    margin-top: 0;
  }
}

@media only screen and (max-height: 250px) {
  .headline {
    padding-top: 30%;
  }
}

@media only screen and (max-height: 600px) and (max-width: 1000px) {
  .headline {
    padding-top: 30%;
  }
}